<template>
  <div class="pa-4 custom-detail-style">
    <div
      v-if="
        getVendorLoadStatus === 2 &&
        this.getVendor &&
        this.getVendor.id !== '' &&
        this.getVendor.id !== null
      "
    >
      <gallery :form="hotel"></gallery>

      <v-row>
        <v-col cols="12" md="10" class="py-0 my-0">
          <div class="mt-5 ml-1 text--secondary">
            <span v-for="(hotel_type, index) in hotelTypes">
              <span>{{ hotel_type.name }}</span>
              <v-icon class="mx-1" v-if="hotelTypes.length > index + 1"
                >mdi-circle-small</v-icon
              >
            </span>
          </div>
          <h5>{{ hotel.name }}</h5>

          <v-rating
            v-model="hotel.star_rating"
            class="py-0 mt-1 ml-0 custom-rating"
            background-color="orange lighten-3"
            length="7"
            color="orange"
            medium
            readonly
          ></v-rating>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            depressed
            color="accent"
            class="float-right mt-8 text-black"
            @click="editVendor"
            v-if="
              getAuthUser.user_type === 'admin' ||
              getAuthUser.user_role === 'master'
            "
            >Edit</v-btn
          >
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <v-row
        class="mt-8"
        v-if="hotel.description !== '' && hotel.description !== null"
      >
        <v-col cols="12" md="12" class="py-0 my-0">
          <label class="text--secondary text-uppercase">Description</label>
        </v-col>
        <v-col cols="12" md="12" class="py-0 my-2">
          {{ hotel.description }}
        </v-col>
      </v-row>

      <!--Address (START)-->
      <v-row
        class="mt-10"
        v-if="
          (hotel.address !== '' && hotel.address !== null) ||
          (hotel.city !== '' && hotel.city !== null) ||
          (hotel.country !== '' && hotel.country !== null)
        "
      >
        <v-col cols="12" md="12" class="py-0 my-0">
          <label class="my-2 text--secondary text-uppercase">Address</label>
        </v-col>
        <v-col cols="12" class="pl-2" md="12">
          <v-icon class="mdi mdi-map-marker"></v-icon>
          {{ hotel.address }}
          <span v-if="hotel.address && hotel.city">,</span>
          {{ hotel.city }}
          <span
            v-if="
              (hotel.city && hotel.country) || (hotel.address && hotel.country)
            "
            >,</span
          >
          {{ hotel.country }}
        </v-col>
      </v-row>
      <!--Address (END)-->

      <!--Hotel Types (START)-->
      <v-row class="mt-10" v-if="hotelTypes.length > 0">
        <v-col cols="12" md="12" class="py-0 mt-0 mb-3">
          <label class="text--secondary text-uppercase">Type of Hotel</label>
        </v-col>
        <v-col cols="12" md="12" class="py-0 my-0">
          <v-item-group>
            <v-container>
              <v-row>
                <v-col
                  v-for="(hotelType, index) in hotelTypes"
                  :key="index"
                  class="p-0 m-0 custom-item-column"
                >
                  <v-item v-slot="{ active }" disabled>
                    <v-card
                      :color="active ? 'primary' : 'default'"
                      class="d-flex bg-color-white align-center"
                      dark
                      height="75"
                      width="75"
                      outlined
                    >
                      <v-scroll-y-transition>
                        <div class="text-center flex-grow-1 black--text">
                          <v-icon
                            color="primary"
                            :class="[hotelType.icon]"
                          ></v-icon>
                          <div>
                            <small>{{ hotelType.name }}</small>
                          </div>
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-col>
      </v-row>
      <!--Hotel Types (END)-->

      <!--Check-in, Check-out, 24 Reception (START)-->
      <v-row class="mt-12">
        <v-col
          cols="12"
          md="4"
          class="py-0 mt-0 mb-3"
          v-if="
            hotel.checkin_time !== '' &&
            hotel.checkin_time !== null &&
            hotel.checkout_time !== '' &&
            hotel.checkout_time !== null
          "
        >
          <label class="text--secondary text-uppercase">Check-in Time</label>
          <div class="py-2">
            {{ moment(hotel.checkin_time, "hh:mm").format("LT") }}
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0 my-0"
          v-if="
            hotel.checkin_time !== '' &&
            hotel.checkin_time !== null &&
            hotel.checkout_time !== '' &&
            hotel.checkout_time !== null
          "
        >
          <label class="text--secondary text-uppercase">Check-out Time</label>
          <div class="py-2">
            {{ moment(hotel.checkout_time, "hh:mm").format("LT") }}
          </div>
        </v-col>

        <v-col cols="12" md="4" class="py-0 my-0">
          <label class="text--secondary text-uppercase">24H Reception</label>
          <div class="py-2">
            {{
              hotel.vendor_specifications &&
              hotel.vendor_specifications.is_reception_always_available
                ? "YES"
                : "NO"
            }}
          </div>
        </v-col>
      </v-row>
      <!--Check-in, Check-out, 24 Reception (END)-->

      <!--Amenities (START)-->
      <div class="mt-10" v-if="hotelFeatures.length > 0">
        <label class="text--secondary text-uppercase">Features</label>

        <div class="flex-wrap py-2 d-flex">
          <div
            v-for="(hotel_feature, index) in hotelFeatures"
            :key="'eq' + index"
            style="width: 166px"
            class="mx-4 my-2 word-break"
          >
            <div>
              <label :class="{ 'text--secondary': !hotel_feature.selected }"
                ><i :class="hotel_feature.icon"></i>
                <span class="ml-5">{{ hotel_feature.name }}</span></label
              >
            </div>
          </div>
        </div>
      </div>
      <!--Amenities (END)-->

      <!--Contact (START)-->
      <v-row
        class="mt-11"
        v-if="hotel.full_name !== '' && hotel.full_name !== null"
      >
        <v-col cols="12" md="12" class="py-0 mt-0 mb-3">
          <label class="text--secondary text-uppercase">Contact People</label>
        </v-col>
        <v-col cols="12" md="6" class="py-0 mt-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-avatar size="80" color="grey">
                <v-img
                  v-if="hotel.profile_picture"
                  contained
                  :src="'/' + hotel.profile_picture"
                  max-width="100px"
                  height="100px"
                  class="mx-auto border rounded-circle"
                >
                </v-img>

                <v-img
                  v-else
                  contained
                  max-width="100px"
                  height="100px"
                  class="mx-auto border rounded-circle"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 secondary white--text"
                      align="center"
                      justify="center"
                    >
                      <v-icon size="60" color="white"
                        >mli-neutral-avatar</v-icon
                      >
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <div>
                  <span
                    v-if="
                      hotel.person_title !== '' && hotel.person_title !== null
                    "
                    >{{ hotel.person_title }}</span
                  >
                  {{ hotel.full_name }}
                </div>
                <div>{{ hotel.email }}</div>
                <div>{{ hotel.phone }}</div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col
          v-if="
            hotel.secondary_full_name !== '' &&
            hotel.secondary_full_name !== null
          "
          cols="12"
          md="6"
          class="py-0 mt-2"
        >
          <v-card>
            <v-list-item three-line>
              <v-list-item-avatar size="80" color="grey">
                <v-img
                  v-if="hotel.secondary_profile_picture"
                  contained
                  :src="'/' + hotel.secondary_profile_picture"
                  max-width="100px"
                  height="100px"
                  class="mx-auto border rounded-circle"
                >
                </v-img>

                <v-img
                  v-else
                  contained
                  max-width="100px"
                  height="100px"
                  class="mx-auto border rounded-circle"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 secondary white--text"
                      align="center"
                      justify="center"
                    >
                      <v-icon size="60" color="white"
                        >mli-neutral-avatar</v-icon
                      >
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <div>
                  <span
                    v-if="
                      hotel.secondary_person_title !== '' &&
                      hotel.secondary_person_title !== null
                    "
                    >{{ hotel.secondary_person_title }}</span
                  >
                  {{ hotel.secondary_full_name }}
                </div>
                <div>{{ hotel.secondary_email }}</div>
                <div>{{ hotel.secondary_phone }}</div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <!--Contact (END)-->

      <!--Rooms (START)-->
      <v-row class="mt-12" v-if="hotel.rooms.length > 0">
        <v-col cols="12" md="12" class="py-0 mt-0 mb-2">
          <label class="text--secondary text-uppercase">Rooms</label>
        </v-col>

        <corporate-rates
          v-if="hotel.corporate_rates && hotel.corporate_rates.length > 0"
          :rates="hotel.corporate_rates"
        ></corporate-rates>

        <v-col cols="12" md="12" class="py-0 mt-0 room-card-background">
          <h6 class="my-4 f-20">Rooms</h6>
          <v-card
            class="pb-3 mt-5 room-card-background"
            v-for="(room, roomIndex) in hotel.rooms"
            :key="'r' + roomIndex"
            v-if="
              room.total_rooms_in_category && room.total_rooms_in_category > 0
            "
          >
            <v-card-text class="px-3 py-0">
              <!--Room Category (START)-->
              <div>
                <v-row
                  class="pt-3 room-title-background custom-card-top-border"
                >
                  <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                    <small class="room-text-color text-uppercase"
                      >Category</small
                    >
                    <div class="text--primary">{{ room.category }}</div>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                    <small class="room-text-color text-uppercase"
                      >Rooms (Calculated)</small
                    >
                    <div class="text--primary">
                      {{ room.total_rooms_in_category }}
                    </div>
                  </v-col>
                </v-row>

                <!--Room Types (START)-->
                <v-row
                  class="pt-3 mt-3 room-types-border"
                  v-for="(room_type, roomTypeIndex) in room.room_details"
                  :key="'rti' + roomTypeIndex"
                >
                  <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                    <small class="room-text-color text-uppercase"
                      >Type of Room</small
                    >
                    <div class="text--primary">
                      {{ room_type.selected_type }}
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                    <small class="room-text-color text-uppercase"
                      >Rooms (Calculated)</small
                    >
                    <div class="text--primary">
                      {{ room_type.total_room_types }}
                    </div>
                  </v-col>
                </v-row>
                <!--Room Types (END)-->
              </div>
              <!--Room Category (END)-->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!--Rooms (END)-->

      <div
        v-if="hotel.vendor_spaces.length > 0"
        class="mt-12 mb-5 text-body-2 text--secondary text-uppercase"
      >
        Meeting & Event Spaces
      </div>
      <v-row v-if="hotel.vendor_spaces.length > 0">
        <v-col cols="12">
          <event-spaces :spaces="hotel.vendor_spaces"></event-spaces>
        </v-col>
      </v-row>

      <!--Food & Beverages (START)-->
      <v-row
        class="mt-12"
        v-if="hotel.restaurants.length > 0 || hotel.bars.length > 0"
      >
        <v-col cols="12" md="12" class="py-0 mt-0 mb-1">
          <label class="text--secondary text-uppercase">Food & Beverages</label>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <!--Restaurants (START)-->
          <v-card
            class="px-3 py-2 mt-3 bg-color-grey"
            outlined
            v-if="hotel.restaurants.length > 0"
          >
            <v-row class="py-0 my-0">
              <v-col cols="12" class="d-flex">
                <v-icon class="mdi mdi-room-service"></v-icon>
                <div class="ml-2">
                  Restaurants
                  <v-chip color="default" class="ml-2" small>{{
                    hotel.restaurants.length
                  }}</v-chip>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <v-row
                  v-for="(restaurant, restaurantIndex) in hotel.restaurants"
                  :key="'ri' + restaurantIndex"
                >
                  <v-col
                    cols="12"
                    md="12"
                    :class="{
                      'room-types-border':
                        hotel.restaurants.length > restaurantIndex + 1,
                    }"
                    class="py-0 mt-0 mb-2"
                  >
                    <div class="font-weight-bold">{{ restaurant.name }}</div>
                    <div>
                      {{ restaurant.cuisines.toString() }} -
                      {{ restaurant.price_range }} -
                      {{ restaurant.seating_capacity }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <!--Restaurants (END)-->

          <!--Bars (START)-->
          <v-card
            class="px-3 py-2 mt-3 bg-color-grey"
            outlined
            v-if="hotel.bars.length > 0"
          >
            <v-row class="py-0 my-0">
              <v-col cols="12" md="12" class="d-flex">
                <v-icon class="mdi mdi-glass-cocktail"></v-icon>
                <div class="ml-2">
                  Bars
                  <v-chip color="default" class="ml-2" small>{{
                    hotel.bars.length
                  }}</v-chip>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <v-row
                  v-for="(bar, barIndex) in hotel.bars"
                  :key="'bi' + barIndex"
                >
                  <v-col
                    cols="12"
                    md="12"
                    :class="{
                      'room-types-border': hotel.bars.length > barIndex + 1,
                    }"
                    class="py-0 mt-0 mb-2"
                  >
                    <div class="font-weight-bold">{{ bar.name }}</div>
                    <div>
                      {{ bar.bar_type }} - {{ bar.price_range }} -
                      {{ bar.seating_capacity }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <!--Bars (END)-->
        </v-col>
      </v-row>
      <!--Food & Beverages (END)-->

      <!--Surrounding & Activities (START)-->
      <v-row class="mt-12" v-if="hotel.ptcs.length > 0">
        <v-col cols="12" md="12" class="py-0 mt-0 mb-0">
          <label class="text--secondary text-uppercase"
            >Surrounding & Activities</label
          >
        </v-col>
      </v-row>
      <v-row v-if="hotel.ptcs.length > 0">
        <v-col cold="12" md="12">
          <v-item-group readonly>
            <v-container>
              <v-row>
                <v-col
                  v-for="(hotelPtc, index) in hotel.ptcs"
                  :key="index"
                  class="p-0 m-0 custom-item-column"
                >
                  <v-item v-slot="{ active }">
                    <v-card
                      :color="active ? 'primary' : 'default'"
                      class="d-flex bg-color-white align-center"
                      dark
                      height="75"
                      width="75"
                      outlined
                    >
                      <v-scroll-y-transition>
                        <div
                          class="text-center flex-grow-1"
                          :class="{ 'black--text': active === false }"
                        >
                          <v-icon
                            :color="active === false ? 'primary' : ''"
                            :class="[hotelPtc.icon]"
                          ></v-icon>
                          <div>
                            <small>{{ hotelPtc.name }}</small>
                          </div>
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-col>
      </v-row>
      <!--Surrounding & Activities (END)-->

      <!--Parking Spaces (START)-->
      <v-row
        class="mt-8"
        v-if="
          hotel.vendor_specifications &&
          hotel.vendor_specifications.total_parking_spaces > 0
        "
      >
        <v-col cols="12" md="12" class="py-0 mt-0 mb-0">
          <label class="text--secondary text-uppercase">Parking Spaces</label>
          <div class="py-2">
            {{ hotel.vendor_specifications.total_parking_spaces }}
          </div>
        </v-col>
      </v-row>
      <!--Parking Spaces (END)-->

      <!--Tours & Activities (START)-->
      <v-row class="mt-10" v-if="hotel.activities.length > 0">
        <v-col cols="12" md="12" class="py-0 mt-0 mb-2">
          <label class="text--secondary text-uppercase"
            >Tours & Activities</label
          >
        </v-col>
        <v-col cols="12" md="12" class="py-0 my-0">
          <v-row
            class="py-0 my-0"
            v-for="(activity, activityIndex) in hotel.activities"
            :key="'esi' + activityIndex"
          >
            <v-col cols="12" md="10" class="pt-0 pb-1 pl-3 my-0">
              <v-icon class="mdi mdi-chevron-right"></v-icon>
              {{ activity.name }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--Tours & Activities (END)-->

      <!--Opening Seasons (START)-->
      <div class="mt-10" v-if="hotel.seasonal_timings.length">
        <label class="text--secondary text-uppercase">Opening Seasons</label>
        <div
          class="d-flex justify-space-between"
          v-for="(openingSeason, index) in hotel.seasonal_timings"
          :key="'seasons_' + index"
        >
          <span>Date</span>
          <p style="width: 190px">
            <span>{{ openingSeason.start_date }}</span>
            <span class="mx-2">-</span>
            <span>{{ openingSeason.end_date }}</span>
          </p>
        </div>
      </div>
      <!--Opening Seasons (END)-->
    </div>

    <div v-if="getVendorLoadStatus === 1">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            type="card-avatar, article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            type="table: table-heading, table-thead, table-tbody, table-tfoot"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import CorporateRates from "@/components/CorporateRates.vue";
const Gallery = () =>
  import(/* webpackChunkName: "gallery" */ "@/components/Gallery");
const EventSpaces = () =>
  import(
    /* webpackChunkName: "event-spaces" */ "@/components/tables/EventSpaces"
  );
export default {
  name: "HotelDetails",
  components: { Gallery, EventSpaces, CorporateRates },
  computed: {
    hotel() {
      if (this.getVendor && this.getVendor !== null && this.getVendor !== "") {
        return this.getVendor;
      }
    },
    allImages() {
      if (this.hotel && this.hotel.gallery) {
        return this.hotel.gallery;
      }
    },
    hotelFeatures() {
      return _.filter(this.hotel.hotel_features, { selected: true });
    },
    outsideImages() {
      return _.filter(this.hotel.gallery, { property: "outside" });
    },
    restaurantsImages() {
      return _.filter(this.hotel.gallery, { property: "restaurant" });
    },
    barsImages() {
      return _.filter(this.hotel.gallery, { property: "bar" });
    },
    hotelTypes() {
      return _.filter(this.hotel.hotel_types, { selected: true });
    },
    roomImages() {
      let images = [];
      _.forEach(this.hotel.rooms, (room) => {
        _.forEach(room.images, (image) => {
          images.push(image);
        });
      });
      return images;
    },
    eventTypes() {
      if (this.hotel.event_spaces) {
        let eventTypes = this.hotel.event_spaces;
        return _.filter(eventTypes, (event) => event.selected === false);
      }
    },
    ...mapGetters(["getVendor", "getVendorLoadStatus", "getAuthUser"]),
  },
  methods: {
    moment,
    editVendor() {
      this.setIsVendorUpdate(true);
      this.setActiveComponent("hotel");
      this.setActiveVendorType("hotel");
      this.setNewVendor(this.getVendor);
      this.setToggleSlider(true);
    },
    capitalizeFirstLetter(words) {
      words = words.replace("_", " ");
      let separateWord = words.toLowerCase().split(" ");
      for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(" ");
    },
    ...mapMutations([
      "setIsVendorUpdate",
      "setActiveComponent",
      "setActiveVendorType",
      "setNewVendor",
      "setToggleSlider",
    ]),
  },
};
</script>

<style scoped>
.height {
  height: 100%;
  border-radius: 10px;
}
.opacity {
  opacity: 0.5;
  -webkit-filter: brightness(50%);
  border-radius: 10px;
}
.radius {
  border-radius: 10px;
}
.image-count {
  position: absolute;
  z-index: 10;
  right: 60px;
  top: 400px;
}
.margin {
  margin-right: 20px;
  margin-top: 20px;
}
.carousel-custom-style {
  border-radius: 10px;
}
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.room-card-background {
  background: #f9f9f9;
}
.room-types-border {
  border-bottom: 1px solid #e3e3e3;
}
.bg-color-white {
  background-color: #ffffff !important;
}
.carousel-item-style {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
}
.text-black {
  color: #333333 !important;
}
.custom-detail-style {
  height: calc(100vh);
}
</style>
<style>
.custom-rating button {
  padding: 0 !important;
}
</style>
